import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postLogin, sendForgetPwdLinkApi, verifyEmailApi, sendVerifyEmailApi, logOut } from '../service/api';
export const initialState = {
  login: [],
  verifyRes: '',
};
export const Login = createAsyncThunk('postLogin', async (data) => {
  try {
    let res = await postLogin(data);
    if (res?.data?.data?.token) {
      localStorage.setItem('token', res.data.data.token);
    }
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const sendForgetPwdLink = createAsyncThunk('sendForgetPwdLinkApi', async (email) => {
  try {
    let res = await sendForgetPwdLinkApi(email);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const verifyEmail = createAsyncThunk('verifyEmailApi', async (email) => {
  try {
    let res = await verifyEmailApi(email);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const sendVerifyEmail = createAsyncThunk('sendVerifyEmailApi', async (email) => {
  try {
    let res = await sendVerifyEmailApi(email);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const handleLogOut = createAsyncThunk('logOut', async () => {
  try {
    let res = await logOut();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearLogin: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.login = [];
    },
  },
  extraReducers: {
    [Login.fulfilled]: (state, action) => {
      state.login = action?.payload?.data;
      return state;
    },
    [Login.rejected]: (state, action) => {
      return (state.login = []);
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.verifyRes = action?.payload?.data || initialState.verifyRes;
      return state;
    },
    [verifyEmail.rejected]: (state, action) => {
      return (state.verifyRes = '');
    },
  },
});

export const { clearLogin } = loginSlice.actions;
export default loginSlice.reducer;
