import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEducationLevelList, getProjectTypeList, getSchoolsList, getNewsTypeApi, getCompetitionGroupApi, getPrivacyApi, getHeardFromApi, getTrackListApi, validRequirementApi } from '../service/api';
const initialState = {
  educationLevel: [],
  projectType: [],
  schools: [],
  newsType: [],
  competitionGroup: [],
  privacyType: [],
  heardFromType: [],
  trackList: [],
};
export const getEducationLevel = createAsyncThunk('getEducationLevelList', async () => {
  try {
    let res = await getEducationLevelList();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getProjectType = createAsyncThunk('getProjectTypeList', async () => {
  try {
    let res = await getProjectTypeList();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getSchools = createAsyncThunk('getSchoolsList', async (params) => {
  try {
    let res = await getSchoolsList(params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getNewsType = createAsyncThunk('getNewsTypeApi', async () => {
  try {
    let res = await getNewsTypeApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getCompetitionGroup = createAsyncThunk('getCompetitionGroupApi', async () => {
  try {
    let res = await getCompetitionGroupApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getPrivacy = createAsyncThunk('getPrivacyApi', async () => {
  try {
    let res = await getPrivacyApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getHeardFrom = createAsyncThunk('getHeardFromApi', async () => {
  try {
    let res = await getHeardFromApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getTrackList = createAsyncThunk('getTrackListApi', async () => {
  try {
    let res = await getTrackListApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const validRequirement = createAsyncThunk('validRequirementApi', async (data) => {
  try {
    let res = await validRequirementApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const educationLevelSlice = createSlice({
  name: 'educationLevel',
  initialState,
  reducers: {},
  extraReducers: {
    [getEducationLevel.fulfilled]: (state, action) => {
      state.educationLevel = action?.payload?.data;
      return state;
    },
    [getEducationLevel.rejected]: (state, action) => {
      return (state.educationLevel = []);
    },
    [getProjectType.fulfilled]: (state, action) => {
      state.projectType = action?.payload?.data;
      return state;
    },
    [getProjectType.rejected]: (state, action) => {
      return (state.projectType = []);
    },
    [getSchools.fulfilled]: (state, action) => {
      state.schools = action?.payload?.data;
      return state;
    },
    [getSchools.rejected]: (state, action) => {
      return (state.schools = []);
    },
    [getNewsType.fulfilled]: (state, action) => {
      state.newsType = action?.payload?.data;
      return state;
    },
    [getNewsType.rejected]: (state, action) => {
      return (state.newsType = []);
    },
    [getCompetitionGroup.fulfilled]: (state, action) => {
      state.competitionGroup = action?.payload?.data;
      return state;
    },
    [getCompetitionGroup.rejected]: (state, action) => {
      return (state.competitionGroup = []);
    },
    [getPrivacy.fulfilled]: (state, action) => {
      state.privacyType = action?.payload?.data;
      return state;
    },
    [getPrivacy.rejected]: (state, action) => {
      return (state.privacyType = []);
    },
    [getHeardFrom.fulfilled]: (state, action) => {
      state.heardFromType = action?.payload?.data;
      return state;
    },
    [getHeardFrom.rejected]: (state, action) => {
      return (state.heardFromType = []);
    },
    [getTrackList.fulfilled]: (state, action) => {
      state.trackList = action?.payload?.data;
      return state;
    },
    [getTrackList.rejected]: (state, action) => {
      return (state.trackList = []);
    },
  },
});

export default educationLevelSlice.reducer;
