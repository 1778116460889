import { Select, message, Upload } from 'antd';
const { Option } = Select;


export const isMobile = () => {
  if (window.screen.width < 500) {
    // 当前设备是移动设备
    return true;
  } else return false;
};

/*
export class DictUtils {
  getDictList(
    dictData,
    dictName,
  ) {
    return dictData[dictName] || [];
  }

  getDictLabel(
    dictData,
    dictName,
    value
  ) {
    let label = '';
    const map = this.getDictList(dictData, dictName);
    map.forEach((arr) => {
      if (arr.value === value) {
        label = arr.label;
      }
    });
    return label;
  }
}*/

export const optionList = (list) => {
  const arr = list?.map((item) => {
    return (
      <Option key={item.name} value={item.name}>
        {item.value}
      </Option>
    );
  });
  return arr;
};

export const yearSelect = (start = 1989, end = 2024) =>
  Array(end - start + 1)
    .fill('')
    .map((item, index) => {
      return (
        <Option key={index + start} value={index + start}>
          {index + start}
        </Option>
      );
    });
export const monthSelect = (
  <>
    <Option value={1}>Jan/一月</Option>
    <Option value={2}>Feb/二月</Option>
    <Option value={3}>Mar/三月</Option>
    <Option value={4}>Apr/四月</Option>
    <Option value={5}>May/五月</Option>
    <Option value={6}>Jun/六月</Option>
    <Option value={7}>Jul/七月</Option>
    <Option value={8}>Aug/八月</Option>
    <Option value={9}>Sep/九月</Option>
    <Option value={10}>Oct/十月</Option>
    <Option value={11}>Nov/十一月</Option>
    <Option value={12}>Dec/十二月</Option>
  </>
);
export const daySelect = Array(31)
  .fill('')
  .map((item, index) => {
    return (
      <Option key={index + 1} value={index + 1}>
        {index + 1}
      </Option>
    );
  });

export const messageCustom = (res, cb, msgucst) => {
  if (res.payload.code === 200 || res.payload.code === 0) {
    message.success(msgucst || 'success'); //res.payload.msg
    if (cb) cb();
  } else {
    message.error(res.payload.msg);
  }
};

export function minLength(rule, value, callback) {
  if (value && value.length < rule.value) {
    callback('100-800 words'); //('不能少于' + rule.value + '字符');
  } else {
    callback();
  }
}

const MILLIION = 1024 * 1024;
//普通图片限制
export const beforeUploadImg = (file) => {
  const isJPG = file.type === 'image/jpg';
  const isJPEG = file.type === 'image/jpeg';
  const isPNG = file.type === 'image/png';
  if (!isJPG && !isJPEG && !isPNG) {
    message.error(`${file.name} is not a jpg, jpeg, png file`);
  } else if (file.size > 2 * MILLIION) {
    message.error(`${file.name} more than 2MB`);
    return Upload.LIST_IGNORE;
  }
  return isJPG || isJPEG || isPNG || Upload.LIST_IGNORE;
};

//pdf限制
export const beforeUploadPdf = (file) => {
  const isVideo = file.type.indexOf('video') !== -1 // 判断是否是视频类型
  const isPDF = file.type === 'application/pdf';
  const isJPG = file.type === 'image/jpg';
  const isJPEG = file.type === 'image/jpeg';
  const isPNG = file.type === 'image/png';
  if (!isPDF && !isJPG && !isJPEG && !isPNG && !isVideo) {
    message.error(`${file.name} is not a pdf, jpg, jpeg, png, mp4 file or other video file`);
  } else if (file.size > 20 * MILLIION) {
    message.error(`${file.name} more than 20MB`);
    return Upload.LIST_IGNORE;
  }
  return isVideo || isPDF || isJPG || isJPEG || isPNG || Upload.LIST_IGNORE;
};
//仅限pdf限制
export const beforeUploadPdfOnly = (file) => {
  const isPDF = file.type === 'application/pdf';
  if (!isPDF) {
    message.error(`${file.name} is not a pdf file`);
  } else if (file.size > 20 * MILLIION) {
    message.error(`${file.name} more than 20MB`);
    return Upload.LIST_IGNORE;
  }
  return isPDF || Upload.LIST_IGNORE;
};

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0;i < vars.length;i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

export const isMainTrack = (name) => {
  if (name?.indexOf('主赛道') > -1 || name?.indexOf('Main Track') > -1) {
    return true;
  } else {
    return false;
  }
};

export const getMainTrackFromLocal = () => {
  return JSON.parse(localStorage.getItem('isMainTrack'));
};

export const setMaxHeight = (className) => {
  let descArr = document.getElementsByClassName(className);
  const arr = Object.values(descArr).map((item) => {
    return item.clientHeight;
  });
  const max = Math.max(...arr);
  Object.values(descArr).forEach((item, index) => {
    item.style.height = `${max}px`;
  });
};

export const getLanguage = () => {
  return localStorage.getItem('languagenew') || 'en-US';
};

//导出excel记录
export const exportExcel = (blobData, fileName) => {
  //请求后台导出excel数据
  const aLink = document.createElement('a');
  document.body.appendChild(aLink);
  aLink.style.display = 'none';
  aLink.href = window.URL.createObjectURL(blobData);
  aLink.setAttribute('download', fileName + '.xls');
  aLink.click();
  document.body.removeChild(aLink);
  message.success('success!')
}