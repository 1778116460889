import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getShortList, getTopicDictList, getTopicDictById } from '../service/api';
export const initialState = {
  shortList: [],
  totalShortListNum: 0,
  topicDictList: []
};
export const searchShortList = createAsyncThunk('getShortList', async (params) => {
  try {
    let res = await getShortList(params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const searchTopicDictList = createAsyncThunk('getTopicDictList', async (params) => {
  try {
    let res = await getTopicDictList(params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const searchTopicDicById = createAsyncThunk('getTopicDictById', async (params) => {
  try {
    let res = await getTopicDictById(params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
})
export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
  },
  extraReducers: {
    [searchShortList.fulfilled]: (state, action) => {
      state.shortList = action?.payload?.data?.records;
      state.totalShortListNum = action?.payload?.data?.total
      return state;
    },
    [searchShortList.rejected]: (state, action) => {
      return (state.shortList = []);
    },
    [searchTopicDictList.fulfilled]: (state, action) => {
      state.topicDictList = action?.payload?.data;
      return state;
    },
    [searchTopicDictList.rejected]: (state, action) => {
      return (state.topicDictList = []);
    },
  },
});

export default trackSlice.reducer;
