import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEntityApi, postEntityApi, putEntityApi, deleteEntityApi, submitEntityApi } from '../service/api';
export const initialState = {
  entityInfo: {},
};
export const getEntity = createAsyncThunk('getEntityApi', async (data) => {
  try {
    let res = await getEntityApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const putEntity = createAsyncThunk('putEntityApi', async (data) => {
  try {
    let res = await putEntityApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const postEntity = createAsyncThunk('postEntityApi', async (data) => {
  try {
    let res = await postEntityApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const deleteEntity = createAsyncThunk('deleteEntityApi', async (id) => {
  try {
    let res = await deleteEntityApi(id);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const submitEntity = createAsyncThunk('submitEntityApi', async (id) => {
  try {
    let res = await submitEntityApi(id);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const entityInfoSlice = createSlice({
  name: 'entityInfo',
  initialState,
  reducers: {
    clearEntityInfo: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.entityInfo = {};
    },
  },
  extraReducers: {
    [getEntity.fulfilled]: (state, action) => {
      state.entityInfo = action?.payload?.data || initialState.entityInfo;
      return state;
    },
    [getEntity.rejected]: (state, action) => {
      return (state.entityInfo = {});
    },
  },
});

export const { clearEntityInfo } = entityInfoSlice.actions;
export default entityInfoSlice.reducer;
