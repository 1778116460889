import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postContestant } from '../service/api';
export const initialState = {
  contestant: [],
};
export const Contestant = createAsyncThunk('postContestant', async (data) => {
  try {
    let res = await postContestant(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const contestantSlice = createSlice({
  name: 'contestant',
  initialState,
  reducers: {
    clearContestant: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.contestant = [];
    },
  },
  extraReducers: {
    [Contestant.fulfilled]: (state, action) => {
      state.contestant = action?.payload?.data;
      return state;
    },
    [Contestant.rejected]: (state, action) => {
      return (state.contestant = []);
    },
  },
});

export const { clearContestant } = contestantSlice.actions;
export default contestantSlice.reducer;
