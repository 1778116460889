import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCountryList, getCountryWithoutChinaList } from "../service/api";
const initialState = {
    country: [],
    countryWithoutChina: []
};
export const getCountry = createAsyncThunk('getCountryList', async () => {
    try {
        let res = await getCountryList()
        return res.data
    } catch (error) {
        console.log(error)
    }
})

export const getCountryWithoutChina = createAsyncThunk('getCountryWithoutChinaList', async () => {
    try {
        let res = await getCountryWithoutChinaList()
        return res.data
    } catch (error) {
        console.log(error)
    }
})
const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getCountry.fulfilled]: (state, action) => {
            state.country = action?.payload?.data;
            return state;
        },
        [getCountry.rejected]: (state, action) => {
            return state.country = []
        },
        [getCountryWithoutChina.fulfilled]: (state, action) => {
            state.countryWithoutChina = action?.payload?.data;
            return state;
        },
        [getCountryWithoutChina.rejected]: (state, action) => {
            return state.countryWithoutChina = []
        }
    }
})

export default countrySlice.reducer;