import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCertificateTypeApi } from "../service/api";
const initialState = {
    certificateType: []
};
export const getCertificateType = createAsyncThunk('getCertificateTypeApi', async () => {
    try {
        let res = await getCertificateTypeApi()
        return res.data
    } catch (error) {
        console.log(error)
    }
})
const certificateTypeSlice = createSlice({
    name: "certificateType",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getCertificateType.fulfilled]: (state, action) => {
            state.certificateType = action?.payload?.data;
            return state;
        },
        [getCertificateType.rejected]: (state, action) => {
            return state.certificateType = []
        }
    }
})

export default certificateTypeSlice.reducer;
