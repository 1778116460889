import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyApi, postCompanyApi, putCompanyApi, deleteCompanyApi, validEntityCompanyStockApi } from '../service/api';
export const initialState = {
  companyInfo: {},
};
export const getCompany = createAsyncThunk('getCompanyApi', async (data) => {
  try {
    let res = await getCompanyApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const putCompany = createAsyncThunk('putCompanyApi', async (data) => {
  try {
    let res = await putCompanyApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const postCompany = createAsyncThunk('postCompanyApi', async (data) => {
  try {
    let res = await postCompanyApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const deleteCompany = createAsyncThunk('deleteCompanyApi', async (id) => {
  try {
    let res = await deleteCompanyApi(id);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const validEntityCompanyStock = createAsyncThunk('validEntityCompanyStockApi', async (data) => {
  try {
    let res = await validEntityCompanyStockApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    clearCompanyInfo: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.companyInfo = {};
    },
  },
  extraReducers: {
    [getCompany.fulfilled]: (state, action) => {
      state.companyInfo = action?.payload?.data || initialState.companyInfo;
      return state;
    },
    [getCompany.rejected]: (state, action) => {
      return (state.companyInfo = {});
    },
  },
});

export const { clearCompanyInfo } = companyInfoSlice.actions;
export default companyInfoSlice.reducer;
