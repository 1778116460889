import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContinentApi } from "../service/api";
const initialState = {
    continent: []
};
export const getContinent = createAsyncThunk('getContinentApi', async () => {
    try {
        let res = await getContinentApi()
        return res.data
    } catch (error) {
        console.log(error)
    }
})
const continentSlice = createSlice({
    name: "continent",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getContinent.fulfilled]: (state, action) => {
            state.continent = action?.payload?.data;
            return state;
        },
        [getContinent.rejected]: (state, action) => {
            return state.continent = []
        }
    }
})

export default continentSlice.reducer;
