import {lazy} from 'react';
import {Redirect} from 'react-router-dom';

const Message = lazy(() => import('@/pages/message'));
const Signup = lazy(() => import('@/pages/signup'));
const Signin = lazy(() => import('@/pages/signin'));
const ForgotPassword = lazy(() => import('@/pages/forgotpassword'));
const About = lazy(() => import('@/pages/about'));
const About2020 = lazy(() => import('@/pages/about/index2020'));
const About2019 = lazy(() => import('@/pages/about/index2019'));
const About2018 = lazy(() => import('@/pages/about/index2018'));
const About2017 = lazy(() => import('@/pages/about/index2017'));
const News = lazy(() => import('@/pages/news'));
const NewsDetail = lazy(() => import('@/pages/news/detail'));
const Invitation = lazy(() => import('@/pages/invitation'));
const Track = lazy(() => import('@/pages/track'));
const OtherLanguage = lazy(() => import('@/pages/otherlanguage'));
const Previous2023 = lazy(() => import('@/pages/previous/index2023'));
const Previous2022 = lazy(() => import('@/pages/previous/index2022'));
const Previous2021 = lazy(() => import('@/pages/previous/index2021'));
const Previous2020 = lazy(() => import('@/pages/previous/index2020'));
const Previous2019 = lazy(() => import('@/pages/previous/index2019'));
const Previous2018 = lazy(() => import('@/pages/previous/index2018'));
const Previous2017 = lazy(() => import('@/pages/previous/index2017'));
const Previous2016 = lazy(() => import('@/pages/previous/index2016'));
const Previous2015 = lazy(() => import('@/pages/previous/index2015'));
const Faq = lazy(() => import('@/pages/faq'));
const ProfileNew = lazy(() => import('@/pages/profilenew'));
const MaterialNew = lazy(() => import('@/pages/materialnew'));
const CompanyNew = lazy(() => import('@/pages/companynew'));
const NotiapplySuc = lazy(() => import('@/pages/notification/applySuc'));
const NotiverifyReg = lazy(() => import('@/pages/notification/verifyReg'));
const Tasks = lazy(() => import('@/pages/tasks'));
const TaskDetail = lazy(() => import('@/pages/tasks/detail'));
const Terms = lazy(() => import('@/pages/terms'));
const Announcements = lazy(() => import('@/pages/announcements'));
const SignUpNext = lazy(() => import('@/pages/notification/signUpNext'));
const SubmitSuc = lazy(() => import('@/pages/notification/submitSuc'));
const ShortList = lazy(() => import('@/pages/shortlist'));
const Prompt = lazy(() => import('@/pages/notification/prompt'));

//render: () => <Redirect to="/blackborad" />
const routes = [
    {path: '/', exact: true, render: () => <Redirect to="/home"/>},
    {
        path: '/home',
        component: Message,
    },
    {
        path: '/signup',
        component: Signup,
    },
    {
        path: '/signin',
        component: Signin,
    },
    {
        path: '/forgotpassword',
        component: ForgotPassword,
    },
    {
        path: '/about',
        component: About,
    },
    {
        path: '/about-pilc-2020',
        component: About2020,
    },
    {
        path: '/about-pilc-2019',
        component: About2019,
    },
    {
        path: '/about-pilc-2018',
        component: About2018,
    },
    {
        path: '/about-pilc-2017',
        component: About2017,
    },
    {
        path: '/news',
        component: News,
    },
    {
        path: '/newsdetail',
        component: NewsDetail,
    },
    {
        path: '/invitation',
        component: Invitation,
    },
    {
        path: '/track',
        component: Track,
    },
    {
        path: '/otherlanguage',
        component: OtherLanguage,
    },
    {
        path: '/previous2023',
        component: Previous2023,
    },
    {
        path: '/previous2022',
        component: Previous2022,
    },
    {
        path: '/previous2021',
        component: Previous2021,
    },
    {
        path: '/previous2020',
        component: Previous2020,
    },
    {
        path: '/previous2019',
        component: Previous2019,
    },
    {
        path: '/previous2018',
        component: Previous2018,
    },
    {
        path: '/previous2017',
        component: Previous2017,
    },
    {
        path: '/previous2016',
        component: Previous2016,
    },
    {
        path: '/previous2015',
        component: Previous2015,
    },
    {
        path: '/faq',
        component: Faq,
    },
    {
        path: '/profilenew',
        component: ProfileNew,
    },
    {
        path: '/materialnew',
        component: MaterialNew,
    },
    {
        path: '/companynew',
        component: CompanyNew,
    },
    {
        path: '/notiapplysuc',
        component: NotiapplySuc,
    },
    {
        path: '/notiverifyreg',
        component: NotiverifyReg,
    },
    {
        path: '/task',
        component: Tasks,
    },
    {
        path: '/taskdetail',
        component: TaskDetail,
    },
    {
        path: '/terms',
        component: Terms,
    },
    {
        path: '/announcements',
        component: Announcements,
    },
    {
        path: '/signupnext',
        component: SignUpNext,
    },
    {
        path: '/submitsuc',
        component: SubmitSuc,
    },
    {
        path: '/shortlist',
        component: ShortList,
    },
    {
        path: '/Prompt',
        component: Prompt
    }
];

export default routes;
