import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-hooks';
// import {configureStore} from '@reduxjs/toolkit';
// const store = configureStore({
//   reducer: {}
// })
import store from '@/states/store.js';

import locale_en from './translations/en-US.json';
import locale_zh from './translations/zh-CN.json';

const data = {
  'zh-CN': locale_zh,
  'en-US': locale_en,
};
const language = localStorage.getItem('languagenew') || 'en-US'

localStorage.setItem('languagenew', language);
//console.log(1,language,data[language])

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider
      locale={language}
      messages={data[language]}
      defaultLocale="en"
    >
      <Provider store={store}>
        <App />
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
