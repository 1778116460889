import request from './request';

//******************首页******************
//轮播图列表
export function getRotations() {
  return request({
    url: '/api/anonym/rotations',
  });
}

//竞赛信息(报名起始时间)
export function getCompetitionInfo() {
  return request({
    url: `/api/anonym/competitionInfo`,
  });
}

//******************新闻页******************
//新闻列表
export function getNews(type) {
  return request({
    url: `/api/anonym/news?newsType=${type}`,
  });
}

//新闻id
export function getNewsById(id) {
  return request({
    url: `/api/anonym/news/${id}`,
  });
}

//企业任务
export function companyTaskApi() {
  return request({
    url: `/api/anonym/companyTask`,
  });
}

//企业任务id
export function getTasksByIdApi(id) {
  return request({
    url: `/api/anonym/companyTask/${id}`,
  });
}

//******************字典******************
//国家列表 (字典)
export function getCountryList() {
  return request({
    url: `/api/static/nationality `,
  });
}

//国家列表 不包含中国 (字典)
export function getCountryWithoutChinaList() {
  return request({
    url: `/api/static/nationalityNoChina`,
  });
}

//学校类型 (字典)
export function getSchoolKind() {
  return request({
    url: `/api/static/kind`,
  });
}

//性别 (字典)
export function getGenderList() {
  return request({
    url: `/api/static/gender`, //0女, 1男, 2其他
  });
}

//学历 (字典)
export function getEducationLevelList() {
  return request({
    url: `/api/static/educationLevel`, //0学士, 1硕士, 2phd, 3其他
  });
}

//参赛类别 (字典)
export function getProjectTypeList() {
  return request({
    url: `/api/static/projectType`,
  });
}

//学院列表 (半字典)
export function getSchoolsList(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `/api/schools/list?${params}`,
  });
}

//新闻类型 (字典)
export function getNewsTypeApi() {
  return request({
    url: `/api/static/newsType`,
  });
}

//竞赛组 (字典)
export function getCompetitionGroupApi() {
  return request({
    url: `/api/static/competitionGroup`,
  });
}

//隐私类型 (字典)
export function getPrivacyApi() {
  return request({
    url: `/api/static/privacy`,
  });
}

//消息来源 (字典)
export function getHeardFromApi() {
  return request({
    url: `/api/static/heardFrom`,
  });
}

//赛道信息 (字典)
export function getTrackListApi() {
  return request({
    method: 'post',
    url: `/api/anonym/contestantTrackValid`,
  });
}

//大洲信息
export function getContinentApi() {
  return request({
    method: 'get',
    url: `/api/static/continent`,
  });
}


//认证类型
export function getCertificateTypeApi() {
  return request({
    method: 'get',
    url: `/api/static/certificateType`,
  });
}


//作品字段信息(校验)
export function validRequirementApi(data) {
  const params = new URLSearchParams(data);
  return request({
    method: 'get',
    url: `/api/entity/validRequirement?${params}`,
  });
}

// 获取入围命题列表
export function getShortList(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `/api/anonym/topicDictPage?${params}`,
  });
}

// 产业命题下拉
export function getTopicDictList(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `/api/anonym/topicDictList?${params}`,
  });
}

// 产业命题 id查询
export function getTopicDictById(id) {
  return request({
    url: `/api/anonym/topicDict/${id}`,
  });
}


//******************参赛者相关******************
//参赛者注册
export function postContestant(data) {
  return request({
    method: 'post',
    url: `/api/anonym/contestant`,
    data,
  });
}

//参赛者登录
export function postLogin(data) {
  return request({
    method: 'post',
    url: `/api/anonym/login`,
    data,
  });
}

//参赛者信息获取
export function getUserInfo() {
  return request({
    url: `/api/team/info`,
  });
}

//忘记密码邮箱发送
export function sendForgetPwdLinkApi(email) {
  return request({
    url: `/api/user/sendForgetPwdLink/${email}`,
  });
}


//注销账号申请
export function closeAccountApplyApi() {
    return request({
        method: 'post',
        url: `/api/user/closeAccountApply`,
    });
}

//邮箱验证(一次)
export function verifyEmailApi(email) {
  return request({
    url: `/api/anonym/verifyEmail/${email}`,
  });
}

//发送邮箱验证邮件
export function sendVerifyEmailApi(email) {
  return request({
    url: `/api/anonym/sendVerifyEmail/${email}`,
  });
}

//******************团队成员相关******************
//团队成员修改
export function putTeamMember(data) {
  return request({
    method: 'put',
    url: `/api/team/member`,
    data,
  });
}

//修改团队成员排序
export function putTeamMemberSort(data) {
  return request({
    method: 'put',
    url: `/api/team/teamMemberUpdateSort`,
    data,
  });
}

//切换参赛组别
export function putChangeGroupApi(groupType) {
  return request({
    method: 'put',
    url: `/api/team/changeCompetitionGroup/${groupType}`,
  });
}

//团队成员列表获取
export function getTeamMemberList(teamId) {
  return request({
    url: `/api/team/member/${teamId}`,
  });
}

//团队成员新增
export function addTeamMember(data) {
  return request({
    method: 'post',
    url: `/api/team/member`,
    data,
  });
}
//团队成员新增(含学校, 大接口)
export function addTeamMemberSchool(data) {
  return request({
    method: 'post',
    url: `/api/team/memberSchool`,
    data,
  });
}
//团队成员修改(含学校, 大接口)
export function editTeamMemberSchool(data) {
  return request({
    method: 'put',
    url: `/api/team/memberSchool`,
    data,
  });
}

//团队成员移除
export function deleteTeamMember(id) {
  return request({
    method: 'delete',
    url: `/api/team/member/${id}`,
  });
}

//团队成员学校新增
export function postTeamMemberSchoolAdd(data) {
  return request({
    method: 'post',
    url: `/api/team/school`,
    data,
  });
}
//团队成员学校修改
export function putTeamMemberSchoolEdit(data) {
  return request({
    method: 'put',
    url: `/api/team/school`,
    data,
  });
}

//******************作品相关******************
//作品查询
export function getEntityApi() {
  return request({
    url: `/api/entity`,
  });
}
//作品新增
export function postEntityApi(data) {
  return request({
    method: 'post',
    url: `/api/entity`,
    data,
  });
}
//作品修改
export function putEntityApi(data) {
  return request({
    method: 'put',
    url: `/api/entity`,
    data,
  });
}

//作品删除
export function deleteEntityApi(id) {
  return request({
    method: 'delete',
    url: `/api/entity/${id}`,
  });
}

//作品提交
export function submitEntityApi() {
  return request({
    url: `/api/entity/submit`,
  });
}

//******************公司相关******************
//公司查询
export function getCompanyApi() {
  return request({
    url: `/api/entity/company`,
  });
}
//公司新增
export function postCompanyApi(data) {
  return request({
    method: 'post',
    url: `/api/entity/company`,
    data,
  });
}
//公司修改
export function putCompanyApi(data) {
  return request({
    method: 'put',
    url: `/api/entity/company`,
    data,
  });
}

//公司删除
export function deleteCompanyApi(id) {
  return request({
    method: 'delete',
    url: `/api/entity/company/${id}`,
  });
}


//校验组别占股百分比
export function validEntityCompanyStockApi(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `/api/entity/validEntityCompanyStock?${params}`,
  });
}

//******************产业命题相关******************
// 导出产业命题
export function exportFile() {
  return request({
    url: `/api/anonym/topicDictExport`,
    responseType: 'blob'
  });
}


//******************end******************

// 退出登录
export function logOut() {
  return request({
    url: '/api/user/logout'
  })
}

// 是否能编辑
export function isEditable() {
  return request({
    url: '/api/user/allowEdit'
  })
}
