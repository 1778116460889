import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNews, companyTaskApi } from '../service/api';
export const initialState = {
  news: [],
  tasks: [],
};
export const getNew = createAsyncThunk('getNews', async (type) => {
  try {
    let res = await getNews(type);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const companyTask = createAsyncThunk('companyTaskApi', async (type) => {
  try {
    let res = await companyTaskApi(type);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const newSlice = createSlice({
  name: 'new',
  initialState,
  reducers: {
    clearNews: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.news = [];
    },
  },
  extraReducers: {
    [getNew.fulfilled]: (state, action) => {
      state.news = action?.payload?.data?.records;
      return state;
    },
    [getNew.rejected]: (state, action) => {
      return (state.news = []);
    },
    [companyTask.fulfilled]: (state, action) => {
      state.tasks = action?.payload?.data?.records;
      return state;
    },
    [companyTask.rejected]: (state, action) => {
      return (state.tasks = []);
    },
  },
});

export const { clearNews } = newSlice.actions;
export default newSlice.reducer;
