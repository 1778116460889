import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGenderList } from "../service/api";
const initialState = {
    gender: []
};
export const getGender = createAsyncThunk('getGenderList', async () => {
    try {
        let res = await getGenderList()
        return res.data
    } catch (error) {
        console.log(error)
    }
})
const genderSlice = createSlice({
    name: "gender",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getGender.fulfilled]: (state, action) => {
            state.gender = action?.payload?.data;
            return state;
        },
        [getGender.rejected]: (state, action) => {
            return state.gender = []
        }
    }
})

export default genderSlice.reducer;