import { configureStore } from '@reduxjs/toolkit';
import rotation from './rotationSlice';
import news from './newSlice';
import newsDetail from './newDetailSlice';
import country from './countrySlice';
import schoolKind from './schoolKindSlice';
import gender from './genderSlice';
import educationLevel from './educationLevelSlice';
import competitionInfo from './competitionInfoSlice';
import contestant from './contestantSlice';
import login from './loginSlice';
import userInfo from './userInfoSlice';
import entityInfo from './entityInfoSlice';
import companyInfo from './companyInfoSlice';
import track from './trackSlice';
import continent from './continentSlice';
import certificateType from './certificateTypeSlice';

export default configureStore({
  reducer: {
    rotation,
    news,
    newsDetail,
    country,
    schoolKind,
    gender,
    educationLevel,
    competitionInfo,
    contestant,
    login,
    userInfo,
    entityInfo,
    companyInfo,
    track,
    continent,
    certificateType,
  },
});
