import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNewsById, getTasksByIdApi } from '../service/api';
export const initialState = {
  newsDetail: {},
  tasksDetail: {},
};
export const getNewDetail = createAsyncThunk('getNewsById', async (id) => {
  try {
    let res = await getNewsById(id);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getTasksById = createAsyncThunk('getTasksByIdApi', async (id) => {
  try {
    let res = await getTasksByIdApi(id);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const newDetailSlice = createSlice({
  name: 'newDetail',
  initialState,
  reducers: {
    clearNewsDetail: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.newsDetail = [];
    },
  },
  extraReducers: {
    [getNewDetail.fulfilled]: (state, action) => {
      state.newsDetail = action?.payload?.data || initialState.newsDetail;
      return state;
    },
    [getNewDetail.rejected]: (state, action) => {
      return (state.newsDetail = {});
    },
    [getTasksById.fulfilled]: (state, action) => {
      state.tasksDetail = action?.payload?.data || initialState.tasksDetail;

      return state;
    },
    [getTasksById.rejected]: (state, action) => {
      return (state.tasksDetail = {});
    },
  },
});

export const { clearNewsDetail } = newDetailSlice.actions;
export default newDetailSlice.reducer;
