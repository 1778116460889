import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRotations } from '../service/api';
export const initialState = {
  rotation: [],
  roIndex: 0,
};
export const getRotation = createAsyncThunk('getRotations', async () => {
  try {
    let res = await getRotations();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const rotationSlice = createSlice({
  name: 'rotation',
  initialState,
  reducers: {
    setIndex: (state, action) => {
      //  console.log(1,action.payload)
      state.roIndex = action.payload;
    },
    clearRotations: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.rotation = [];
    },
  },
  extraReducers: {
    [getRotation.fulfilled]: (state, action) => {
      state.rotation = action?.payload?.data;
      return state;
    },
    [getRotation.rejected]: (state, action) => {
      return (state.rotation = []);
    },
  },
});

export const { clearRotations, setIndex } = rotationSlice.actions;
export default rotationSlice.reducer;
