import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo, putTeamMember, putTeamMemberSort,getTeamMemberList, addTeamMember, addTeamMemberSchool, editTeamMemberSchool, deleteTeamMember, postTeamMemberSchoolAdd, putTeamMemberSchoolEdit, putChangeGroupApi, isEditable,closeAccountApplyApi } from '../service/api';
import { isMainTrack } from 'utils';
export const initialState = {
  userInfo: {
    id: '',
    teamId: '',
    school: {
      location: '',
    },
  },
  teamUserList: [],
  isTeacher: -1,
  memberCount: -1,
  competitionGroupType: '',
  isMainTrackTeam: false,
  isEditable: true
};
export const UserInfo = createAsyncThunk('getUserInfo', async (data) => {
  try {
    let res = await getUserInfo(data);
    const result = await isEditable();
    localStorage.setItem('isMainTrack', `${isMainTrack(res.data.data.trackName)}` || 'true');
    if (isMainTrack(res.data.data.trackName) && !result?.data?.data) {
      window.location.href = '/prompt';
    }
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const putUserInfo = createAsyncThunk('putTeamMember', async (data) => {
  try {
    let res = await putTeamMember(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const putTeamSort = createAsyncThunk('putTeamMemberSort', async (data) => {
  try {
    let res = await putTeamMemberSort(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const putChangeGroup = createAsyncThunk('putChangeGroupApi', async (data) => {
  try {
    let res = await putChangeGroupApi(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const closeAccountApply = createAsyncThunk('closeAccountApply', async () => {
  try {
    let res = await closeAccountApplyApi();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const addUserInfo = createAsyncThunk('addTeamMember', async (data) => {
    try {
        let res = await addTeamMember(data);
        return res.data;
    } catch (error) {
        console.log(error);
    }
});
export const addUserSchoolInfo = createAsyncThunk('addTeamMemberSchool', async (data) => {
  try {
    let res = await addTeamMemberSchool(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const editUserSchoolInfo = createAsyncThunk('editTeamMemberSchool', async (data) => {
  try {
    let res = await editTeamMemberSchool(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const deleteUserInfo = createAsyncThunk('deleteTeamMember', async (data) => {
  try {
    let res = await deleteTeamMember(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const teamMemberSchoolAdd = createAsyncThunk('postTeamMemberSchoolAdd', async (data) => {
  try {
    let res = await postTeamMemberSchoolAdd(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const teamMemberSchoolEdit = createAsyncThunk('putTeamMemberSchoolEdit', async (data) => {
  try {
    let res = await putTeamMemberSchoolEdit(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const getTeamMembers = createAsyncThunk('getTeamMemberList', async (data) => {
  try {
    let res = await getTeamMemberList(data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    updateUser: (state, params) => {
      state.teamUserList = params.payload;
    },
    updateMemberCount: (state, params) => {
      if (params.payload > 0) state.memberCount = params.payload;
    },
    updateGroup: (state, params) => {
      state.userInfo.competitionGroupType = params.payload;
      state.competitionGroupType = params.payload || state.userInfo.competitionGroupType;
    },
    saveIsTeacher: (state, params) => {
      state.isTeacher = params.payload;
    },
    clearUserInfo: (state) => {
      console.log('清空reduxjs/toolkit使用axios从服务器上获取的数据');
      state.userInfo = {};
    },
    updateIsMainTrackTeam: (state, params) => {
      state.isMainTrackTeam = params.payload;
    }
  },
  extraReducers: {
    [UserInfo.fulfilled]: (state, action) => {
      state.userInfo = action?.payload?.data || initialState.userInfo;
      return state;
    },
    [UserInfo.rejected]: (state, action) => {
      return (state.userInfo = {});
    },
    [getTeamMembers.fulfilled]: (state, action) => {
      state.teamUserList = action?.payload?.data || initialState.teamUserList;
      return state;
    },
    [getTeamMembers.rejected]: (state, action) => {
      return (state.teamUserList = []);
    },
  },
});

export const { clearUserInfo, updateUser, updateGroup, saveIsTeacher, updateMemberCount, updateIsMainTrackTeam } = userInfoSlice.actions;
export default userInfoSlice.reducer;
