import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompetitionInfo } from "../service/api";
const initialState = {
    competitionInfo: []
};
export const CompetitionInfo = createAsyncThunk('getCompetitionInfo', async () => {
    try {
        let res = await getCompetitionInfo()
        return res.data
    } catch (error) {
        console.log(error)
    }
})
const competitionInfoSlice = createSlice({
    name: "competitionInfo",
    initialState,
    reducers: {
        decrement: (state) => {
            console.log('competitionInfoSlice...decrement')
            state.counter = state.counter - 1;
        },
        increment: (state) => {
            console.log('competitionInfoSlice...increment')
            state.counter = state.counter + 1;
        }
    },
    extraReducers: {
        [CompetitionInfo.fulfilled]: (state, action) => {
            state.competitionInfo = action?.payload?.data;
            return state;
        },
        [CompetitionInfo.rejected]: (state, action) => {
            return state.competitionInfo = []
        }
    }
})

export const { decrement, increment } = competitionInfoSlice.actions;
export default competitionInfoSlice.reducer;