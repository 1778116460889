import axios from 'axios';
import { TIME_OUT, BASE_URL } from './config'; //LOCAL_URL,
import { message } from 'antd';

const language = localStorage.getItem('languagenew') || 'en-US'
const TOKEN_KEY = 'token';
const token = localStorage.getItem('token') || ''

/**
 * 保存token
 * @param {*} token
 * @returns
 */
 export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token)

 /**
  * 获取token
  * @returns token
  */
 export const getToken = () => localStorage.getItem(TOKEN_KEY)

  /**
  * 移除token
  * @returns token
  */
   export const removeToken = () => localStorage.removeItem(TOKEN_KEY)

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    headers: {
        'Accept-Language': language,
        'Authorization': token
    },
    withCredentials: true
});

// 添加请求拦截器
// instance.interceptors.request.use(
//     function (config) {
//       // 在发送请求之前做些什么，如果有token
//       console.log(config);
//       return config
//     },
//     function (error) {
//       // 对请求错误做些什么
//       return Promise.reject(error)
//     }
//   )
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // 对token过期进行统一的处理
        if (!error.response) {
            // 如果error信息中没有response,网络超时导致
            message.error('网络繁忙，请稍后重试')
            return Promise.reject('网络繁忙，请稍后重试')
        }
        if (error.response.status === 401) {
            // 1. 删除token
            removeToken()
            // 2. 给提示消息
            message.warning('登录信息过期了')
            // 3. 跳转到登录页
            window.location.href = '/signin'
        }
        return Promise.reject(error)
    }
)

export default instance;